<template>
  <div class="container">
    <div class="text-end p-3">
      <c-primary-button v-on:click="newDof"> Crear DOF </c-primary-button>
    </div>
    <c-card title="Publicaciones del Diario Oficial de la Federación">
      <template v-slot:header-right>
        <div class="input-group mb-3 header-search">
          <input
            v-model="searchValue"
            type="text"
            class="form-control"
            @keyup="search"
          />
          <span class="input-group-text">
            <i class="fa-solid fa-magnifying-glass"></i>
          </span>
        </div>
      </template>
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Titulo</th>
            <th>Dependencia</th>
            <th>Resumen</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="dof in dofs" :key="dof.id">
            <td>{{ dof.title }}</td>
            <td>{{ dof.dependency }}</td>
            <td>{{ dof.resume }}</td>
            <td class="text-nowrap">
              <router-link
                class="btn btn-secondary btn-sm ms-2 d-inline-block"
                :to="`/admin/dofs/${dof.id}`"
                ><i class="fa-solid fa-pen"></i
              ></router-link>
              <a
                class="btn btn-secondary btn-sm ms-2 d-inline-block"
                href="javascript:void(0)"
                @click="deleteDof(dof.id)"
                ><i class="fa-solid fa-trash-can"></i
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
    </c-card>
  </div>
</template>
<script>
import CCard from "@/components/CCard.vue";
import CPrimaryButton from "@/components/CPrimaryButton.vue";
import { all, remove } from "@/services/dofs";

export default {
  components: {
    CCard,
    CPrimaryButton,
  },
  data() {
    return {
      dof: {
        content: "Documento nuevo",
      },
      dofs: [],
      timer: null,
      searchValue: "",
    };
  },
  mounted() {
    this.getDofs();
  },
  methods: {
    newDof() {
      this.$router.push(`/admin/dofs/crear`);
    },
    deleteDof(id) {
      const confirm = window.confirm(
        `¿Estás seguro de eliminar la publicación?`
      );
      if (confirm) {
        remove(id).then(() => {
          this.getDofs();
        });
      }
    },
    getDofs(params = {}) {
      all(params)
        .then((response) => {
          this.dofs = response.data;
        })
        .catch(() => {
          alert("No pudimos cargar los Dofs");
        });
    },
    search() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.getDofs({ title: this.searchValue });
      }, 800);
    },
  },
};
</script>
